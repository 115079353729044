import { MessageResponse, PollAttachment } from './types';

export type Poll = {
    question: string;
    choices: string[];
    // Votes point to the index in the choices array
    votes: number[];
    ownVote: number | null;
};

export const getReactionType = (index: number) => `poll_vote_${index}`;

export const getPoll = (message: MessageResponse): Poll | undefined => {
    const pollAttachment = message.attachments?.find((attachment) => attachment.type === 'poll');

    if (pollAttachment?.type !== 'poll') {
        return;
    }

    const { question, choices } = pollAttachment;

    const votes = choices.map((_, index) => message.reaction_counts[getReactionType(index)] ?? 0);
    const ownVote = choices.findIndex((_, index) =>
        message.own_reactions.find((reaction) => reaction.type === getReactionType(index))
    );

    return {
        question,
        choices,
        votes,
        ownVote: ownVote >= 0 ? ownVote : null,
    };
};
