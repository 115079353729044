import { User } from '@internal/api/types';
import {
    MessageResponse as GenericMessageResponse,
    UserResponse as GenericUserResponse,
    Channel as GenericChannel,
    Event as GenericEvent,
    ChannelResponse as GenericChannelResponse,
    ChannelMemberResponse as GenericChannelMemberResponse,
    ConnectionOpen as GenericConnectionOpen,
    ReactionResponse as GenericReactionResponse,
    ChannelState as GenericChannelState,
} from 'stream-chat';
import { ReactionType } from '@internal/ui/features/reactions/types';

export enum ChannelType {
    Room = 'room',
    Support = 'support',
    Mod = 'mod',
}

export type GiphyResponse = {
    height: string;
    width: string;
    size: string;
    url: string;
    frames: string;
};

export type GiphyPreview = {
    query: string;
    height: number;
    width: number;
    url: string;
    queryUrl: string;
};

export enum MessageType {
    Text = 'text',
    Reaction = 'reaction',
}

export interface ChatUser {
    name: string;
    id: string;
}

export type Message = {
    id: string;
    timestamp?: number;
    participantID: string;
    participantName: string;
    avatarURL: string;
    userID?: string;
    reactions: {
        up: ChatUser[];
        down: ChatUser[];
    };
    body: string;
};

export enum EphemeralMessageType {
    SupportPrompt = 'Support Prompt',
    SupportResolved = 'Support Resolved',
}

export type MessageCustomData = {
    mentioned_users?: Pick<User, 'name' | 'id'>[];
    ephemeral_type?: EphemeralMessageType;
    staff?: boolean;
    support?: boolean;
};

export type GiphyAttachment = {
    type: 'giphy';
    url: string;
    query: string;
};

// @see https://github.com/playback-sports/api/blob/master/pkg/polls.go
export type PollAttachment = {
    type: 'poll';
    question: string;
    choices: string[];
};

export type StreamChatGenerics = {
    attachmentType: GiphyAttachment | PollAttachment;
    channelType: { name?: string };
    commandType: string;
    eventType: {};
    messageType: {
        customData?: MessageCustomData;
    };
    reactionType: { type: ReactionType };
    userType: {
        image?: string;
        flair?: { [roomId: string]: string };
    };
};

export type ChannelMemberResponse = GenericChannelMemberResponse<StreamChatGenerics>;

export type MessageResponse = GenericMessageResponse<StreamChatGenerics>;

export type UserResponse = GenericUserResponse<StreamChatGenerics>;

export type ChannelResponse = GenericChannelResponse<StreamChatGenerics>;

export type ReactionResponse = GenericReactionResponse<StreamChatGenerics>;

export type Channel = GenericChannel<StreamChatGenerics>;

export type ChannelState = GenericChannelState<StreamChatGenerics>;

export type Event = GenericEvent<StreamChatGenerics>;

export type ConnectionOpen = GenericConnectionOpen<StreamChatGenerics>;
